@import '_globals.less';

@userpanelHeight: 48px;

// USERPANEL
// ============================

.userpanel {
    background-color: @c-blue-100;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 101;

    a,
    h3 {
        color: @c-white;
    }

    input[type=submit],
    a.button,
    div.button.multilingual {
        .mx-button-white() !important;
    }

    a.button:before {
        color: @c-primary-1;
    }

    .link {
        &:hover,
        &:focus {
            color: @c-white;
            text-decoration: underline;
        }

        &.intern:before {
            background-image: url('img/ico-arrow-right-white.svg');
        }
    }

    .wrapper {
        padding: 0;
    }

    .close-expander {
        position: absolute;
        right: 0;

        a {
            cursor: pointer;

            &:before {
                content: '=';
                display: inline-block;
                font-family: 'Iconfont';
                margin-right: 10px;
                text-transform: lowercase;
                transform: rotate(180deg);
            }
        }
    }

    .expanded-view {
        display: none;
        padding-bottom: 13px;
    }

    input[type="radio"],
    input[type="checkbox"] {
        &:focus {
            + label {
                &:before {
                    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
                }
            }
        }
    }
}

.userpanel__items {
    display: flex;
    font-size: 0;
    list-style: none;
    position: relative;

    > li {
        display: flex;
        font-size: 15px;

        &:first-child {
            .isLoggedIn & {
                order: 4;
                margin-left: auto;
            }
        }

        > a {
            background-color: @c-blue-100;
            display: block;
            height: @userpanelHeight;
            line-height: @userpanelHeight;
            padding: 0 26px;
            text-transform: uppercase;
            transition: background-color .3s;
            white-space: nowrap;

            &:hover,
            &.on {
                background-color: @c-blue-400;
            }
        }
    }
}


// LOGIN FORM
// ============================

.userpanel .loginform {
    align-items: center;
    display: flex;
    overflow: hidden;
    transition: width .5s;
    white-space: nowrap;
    width: 0;

    label {
        display: inline-block;

        > input {
            margin: 0 10px;
            padding: 2px;
            width: 173px;
        }
    }

    input[type=submit] {
        padding: 2px 15px;
    }
}

.userpanel.login {
    .loginform {
        margin-left: 26px;
    }

    .expanded-view {
        height: 104px;
    }

    .expanded-view > a {
        display: inline-block;
        margin-left: 85px;
    }

    .error-note {
        display: inline-block;
        margin-bottom: 10px;
        margin-left: 85px;
        width: 300px;
    }

    .error-note + a {
        margin-left: 26px;
    }
}


// USER AREA
// ============================

.userpanel .userarea {
    &:before {
        content: 'u';
        font-family: 'Iconfont';
        margin-right: 5px;
        text-transform: lowercase;
    }

    .deletenote {
        margin-top: 15px;

        .delete-link {
            font-weight: 800;
            text-decoration: underline;
        }
    }
}

.userpanel.user {
    .expanded-view {
        font-size: 0;
    }

    a.userAccount {
        bottom: -30px;
        position: absolute;
        right: 26px;

        &:before {
            content: 'a';
            font-family: 'Iconfont';
            margin-right: 5px;
        }
    }

    .expanded-view > div {
        display: inline-block;
        font-size: 15px;
        vertical-align: top;
    }

    .expanded-view > .seminar-list {
        width: 40%;

        h3 {
            margin-left: 10px;
            margin-top: 10px;
        }

        span {
            display: block;
        }
    }

    .expanded-view > .seminar-list > div > div {
        cursor: pointer;
        margin-bottom: 10px;
        padding: 10px;
        transition: background-color .3s;

        &:hover {
            background-color: @c-blue-300;
        }
    }

    .expanded-view > .seminar-list > div > div.on {
        background-color: @c-blue-300;
    }

    .expanded-view > .detail {
        background-color: @c-blue-300;
        border-left: 1px solid @c-white;
        border-right: 1px solid @c-white;
        box-sizing: border-box;
        margin-bottom: 26px;
        min-height: 495px;
        padding: 10px 1.5% 18px;
        position: relative;
        width: 60%;

        .button {
            display: inline-block;
            margin-top: 10px;
        }

        .feedback {
            textarea {
                background-color: @c-lightblue-100;
                border: none;
                box-sizing: border-box;
                color: @c-white;
                font-weight: 400;
                height: 170px;
                margin-bottom: 10px;
                overflow: auto;
                padding: 10px;
                resize: none;
                width: 100%;
            }

            p {
                background-color: @c-lightblue-100;
                border: none;
                box-sizing: border-box;
                color: @c-white;
                font-weight: 400;
                height: 170px;
                margin-bottom: 10px;
                overflow: auto;
                padding: 10px;
                resize: none;
                width: 100%;
            }

            textarea + .button {
                margin-bottom: 10px;
            }
        }

        input[type="submit"] {
            display: inline-block;
        }
    }

    .expanded-view > .detail > div > div {
        background-color: @c-blue-300;
        display: none;
        height: 100%;
    }

    .expanded-view > .detail > div > div.on {
        display: block;
    }
}


// SIGNUP
// ============================

.userpanel.signup {
    fieldset {
        font-size: 0;
        margin-top: 15px;
        padding: 0 26px;
    }

    .register {
        display: inline-block;
        vertical-align: top;
    }

    .register > h2 {
        color: @c-white;
        font-size: 18px;
        margin-bottom: 35px;
    }

    .register.one {
        width: 38%;
    }

    .register.two {
        border-left: 1px solid @c-white;
        margin-left: 26px;
        padding-left: 26px;
        width: 45%;
    }

    .femanager_submit {
        margin-right: 26px;
        text-align: right;
    }

    fieldset > .error-note {
        margin-bottom: 10px;
        margin-top: 0;
    }
}


// BOOKMARKS
// ============================

.userpanel li a.bookmark:before {
    content: 'b';
    font-family: 'Iconfont';
    margin-right: 5px;
    text-transform: lowercase;
}

.bookmark-count {
    background-color: @c-primary-3;
    border-radius: 15px;
    display: inline-block;
    line-height: 24px;
    margin-left: 8px;
    padding: 2px 10px 2px 9px;
}

.bookmark-list {
    .expanded-view {
        font-size: 0;
    }

    .expanded-view > p {
        font-size: 15px;
        margin-left: 26px;
    }

    .expanded-view > div {
        display: inline-block;
        font-size: 15px;
        vertical-align: top;
    }

    .seminar-list {
        width: 40%;

        h2 {
            color: @c-white;
            font-size: 16px;
            margin-left: 10px;
            margin-top: 10px;
        }

        .remove {
            bottom: 0;
            display: block;
            height: 25px;
            margin: auto;
            position: absolute;
            right: 15px;
            top: 0;

            &:before {
                content: 'i';
                font-family: 'Iconfont';
                font-size: 20px;
                transition: color .2s;
            }

            &:hover {
                &:before {
                    color: tint(@c-area-blue, 60%);
                }
            }
        }

        span {
            display: block;
        }
    }

    .seminar-list > div {
        cursor: pointer;
        margin-bottom: 10px;
        padding: 10px 50px 10px 10px;
        position: relative;
        transition: background-color .3s;

        &:hover {
            background-color: @c-blue-300;
        }
    }

    .seminar-list > div.on {
        background-color: @c-blue-300;
    }

    .detail {
        background-color: @c-blue-300;
        border-left: 1px solid @c-white;
        border-right: 1px solid @c-white;
        box-sizing: border-box;
        margin-bottom: 26px;
        min-height: 495px;
        overflow: hidden;
        padding: 10px 1.5% 18px;
        position: relative;
        width: 60%;

        h2 {
            color: @c-white;
            font-size: 16px;
        }

        .infos {
            display: block;
        }

        .download {
            margin-left: 22px;
        }

        .button.buy {
            color: #f32837;
            display: block;
            margin-top: 26px;
            width: 82px;

            &:hover {
                background-color: @c-white;
            }

            &:before {
                content: none;
            }
        }

        .multilingual {
            a {
                color: @c-primary-1;

                &:last-child {
                    padding-right: 0;
                }

                &:hover {
                    background-color: @c-white;
                }
            }
        }

        input[type="submit"] {
            display: inline-block;
        }

        .button {
            display: inline-block;
        }
    }

    .detail > div {
        background-color: @c-blue-300;
        display: none;
        height: 100%;
    }

    .detail > div.on {
        display: block;
    }
}

#femanager_field_agreement_email_use_wrapper {
    input[type="checkbox"] + label {
        margin-bottom: 15px;
        padding: 0;
        text-indent: 24px;
    }
}