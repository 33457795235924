.userpanel {
  background-color: #1968a8;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 101;
}
.userpanel a,
.userpanel h3 {
  color: #ffffff;
}
.userpanel input[type=submit],
.userpanel a.button,
.userpanel div.button.multilingual {
  background-color: #ffffff !important;
  color: #0080bc !important;
}
.userpanel input[type=submit]:hover,
.userpanel a.button:hover,
.userpanel div.button.multilingual:hover,
.userpanel input[type=submit]:focus,
.userpanel a.button:focus,
.userpanel div.button.multilingual:focus {
  background-color: #4da6d0 !important;
  color: #ffffff !important;
}
.userpanel input[type=submit].inactive,
.userpanel a.button.inactive,
.userpanel div.button.multilingual.inactive,
.userpanel input[type=submit][disabled],
.userpanel a.button[disabled],
.userpanel div.button.multilingual[disabled] {
  cursor: default !important;
  opacity: 0.6 !important;
  pointer-events: none !important;
}
.userpanel input[type=submit].inactive:before,
.userpanel a.button.inactive:before,
.userpanel div.button.multilingual.inactive:before,
.userpanel input[type=submit][disabled]:before,
.userpanel a.button[disabled]:before,
.userpanel div.button.multilingual[disabled]:before {
  display: none !important;
}
.userpanel a.button:before {
  color: #0080bc;
}
.userpanel .link:hover,
.userpanel .link:focus {
  color: #ffffff;
  text-decoration: underline;
}
.userpanel .link.intern:before {
  background-image: url('img/ico-arrow-right-white.svg');
}
.userpanel .wrapper {
  padding: 0;
}
.userpanel .close-expander {
  position: absolute;
  right: 0;
}
.userpanel .close-expander a {
  cursor: pointer;
}
.userpanel .close-expander a:before {
  content: '=';
  display: inline-block;
  font-family: 'Iconfont';
  margin-right: 10px;
  text-transform: lowercase;
  transform: rotate(180deg);
}
.userpanel .expanded-view {
  display: none;
  padding-bottom: 13px;
}
.userpanel input[type="radio"]:focus + label:before,
.userpanel input[type="checkbox"]:focus + label:before {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
}
.userpanel__items {
  display: flex;
  font-size: 0;
  list-style: none;
  position: relative;
}
.userpanel__items > li {
  display: flex;
  font-size: 15px;
}
.isLoggedIn .userpanel__items > li:first-child {
  order: 4;
  margin-left: auto;
}
.userpanel__items > li > a {
  background-color: #1968a8;
  display: block;
  height: 48px;
  line-height: 48px;
  padding: 0 26px;
  text-transform: uppercase;
  transition: background-color 0.3s;
  white-space: nowrap;
}
.userpanel__items > li > a:hover,
.userpanel__items > li > a.on {
  background-color: #4182b8;
}
.userpanel .loginform {
  align-items: center;
  display: flex;
  overflow: hidden;
  transition: width 0.5s;
  white-space: nowrap;
  width: 0;
}
.userpanel .loginform label {
  display: inline-block;
}
.userpanel .loginform label > input {
  margin: 0 10px;
  padding: 2px;
  width: 173px;
}
.userpanel .loginform input[type=submit] {
  padding: 2px 15px;
}
.userpanel.login .loginform {
  margin-left: 26px;
}
.userpanel.login .expanded-view {
  height: 104px;
}
.userpanel.login .expanded-view > a {
  display: inline-block;
  margin-left: 85px;
}
.userpanel.login .error-note {
  display: inline-block;
  margin-bottom: 10px;
  margin-left: 85px;
  width: 300px;
}
.userpanel.login .error-note + a {
  margin-left: 26px;
}
.userpanel .userarea:before {
  content: 'u';
  font-family: 'Iconfont';
  margin-right: 5px;
  text-transform: lowercase;
}
.userpanel .userarea .deletenote {
  margin-top: 15px;
}
.userpanel .userarea .deletenote .delete-link {
  font-weight: 800;
  text-decoration: underline;
}
.userpanel.user .expanded-view {
  font-size: 0;
}
.userpanel.user a.userAccount {
  bottom: -30px;
  position: absolute;
  right: 26px;
}
.userpanel.user a.userAccount:before {
  content: 'a';
  font-family: 'Iconfont';
  margin-right: 5px;
}
.userpanel.user .expanded-view > div {
  display: inline-block;
  font-size: 15px;
  vertical-align: top;
}
.userpanel.user .expanded-view > .seminar-list {
  width: 40%;
}
.userpanel.user .expanded-view > .seminar-list h3 {
  margin-left: 10px;
  margin-top: 10px;
}
.userpanel.user .expanded-view > .seminar-list span {
  display: block;
}
.userpanel.user .expanded-view > .seminar-list > div > div {
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;
  transition: background-color 0.3s;
}
.userpanel.user .expanded-view > .seminar-list > div > div:hover {
  background-color: #4082b7;
}
.userpanel.user .expanded-view > .seminar-list > div > div.on {
  background-color: #4082b7;
}
.userpanel.user .expanded-view > .detail {
  background-color: #4082b7;
  border-left: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  box-sizing: border-box;
  margin-bottom: 26px;
  min-height: 495px;
  padding: 10px 1.5% 18px;
  position: relative;
  width: 60%;
}
.userpanel.user .expanded-view > .detail .button {
  display: inline-block;
  margin-top: 10px;
}
.userpanel.user .expanded-view > .detail .feedback textarea {
  background-color: #70a1c9;
  border: none;
  box-sizing: border-box;
  color: #ffffff;
  font-weight: 400;
  height: 170px;
  margin-bottom: 10px;
  overflow: auto;
  padding: 10px;
  resize: none;
  width: 100%;
}
.userpanel.user .expanded-view > .detail .feedback p {
  background-color: #70a1c9;
  border: none;
  box-sizing: border-box;
  color: #ffffff;
  font-weight: 400;
  height: 170px;
  margin-bottom: 10px;
  overflow: auto;
  padding: 10px;
  resize: none;
  width: 100%;
}
.userpanel.user .expanded-view > .detail .feedback textarea + .button {
  margin-bottom: 10px;
}
.userpanel.user .expanded-view > .detail input[type="submit"] {
  display: inline-block;
}
.userpanel.user .expanded-view > .detail > div > div {
  background-color: #4082b7;
  display: none;
  height: 100%;
}
.userpanel.user .expanded-view > .detail > div > div.on {
  display: block;
}
.userpanel.signup fieldset {
  font-size: 0;
  margin-top: 15px;
  padding: 0 26px;
}
.userpanel.signup .register {
  display: inline-block;
  vertical-align: top;
}
.userpanel.signup .register > h2 {
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 35px;
}
.userpanel.signup .register.one {
  width: 38%;
}
.userpanel.signup .register.two {
  border-left: 1px solid #ffffff;
  margin-left: 26px;
  padding-left: 26px;
  width: 45%;
}
.userpanel.signup .femanager_submit {
  margin-right: 26px;
  text-align: right;
}
.userpanel.signup fieldset > .error-note {
  margin-bottom: 10px;
  margin-top: 0;
}
.userpanel li a.bookmark:before {
  content: 'b';
  font-family: 'Iconfont';
  margin-right: 5px;
  text-transform: lowercase;
}
.bookmark-count {
  background-color: #bac013;
  border-radius: 15px;
  display: inline-block;
  line-height: 24px;
  margin-left: 8px;
  padding: 2px 10px 2px 9px;
}
.bookmark-list .expanded-view {
  font-size: 0;
}
.bookmark-list .expanded-view > p {
  font-size: 15px;
  margin-left: 26px;
}
.bookmark-list .expanded-view > div {
  display: inline-block;
  font-size: 15px;
  vertical-align: top;
}
.bookmark-list .seminar-list {
  width: 40%;
}
.bookmark-list .seminar-list h2 {
  color: #ffffff;
  font-size: 16px;
  margin-left: 10px;
  margin-top: 10px;
}
.bookmark-list .seminar-list .remove {
  bottom: 0;
  display: block;
  height: 25px;
  margin: auto;
  position: absolute;
  right: 15px;
  top: 0;
}
.bookmark-list .seminar-list .remove:before {
  content: 'i';
  font-family: 'Iconfont';
  font-size: 20px;
  transition: color 0.2s;
}
.bookmark-list .seminar-list .remove:hover:before {
  color: #99cce4;
}
.bookmark-list .seminar-list span {
  display: block;
}
.bookmark-list .seminar-list > div {
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px 50px 10px 10px;
  position: relative;
  transition: background-color 0.3s;
}
.bookmark-list .seminar-list > div:hover {
  background-color: #4082b7;
}
.bookmark-list .seminar-list > div.on {
  background-color: #4082b7;
}
.bookmark-list .detail {
  background-color: #4082b7;
  border-left: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  box-sizing: border-box;
  margin-bottom: 26px;
  min-height: 495px;
  overflow: hidden;
  padding: 10px 1.5% 18px;
  position: relative;
  width: 60%;
}
.bookmark-list .detail h2 {
  color: #ffffff;
  font-size: 16px;
}
.bookmark-list .detail .infos {
  display: block;
}
.bookmark-list .detail .download {
  margin-left: 22px;
}
.bookmark-list .detail .button.buy {
  color: #f32837;
  display: block;
  margin-top: 26px;
  width: 82px;
}
.bookmark-list .detail .button.buy:hover {
  background-color: #ffffff;
}
.bookmark-list .detail .button.buy:before {
  content: none;
}
.bookmark-list .detail .multilingual a {
  color: #0080bc;
}
.bookmark-list .detail .multilingual a:last-child {
  padding-right: 0;
}
.bookmark-list .detail .multilingual a:hover {
  background-color: #ffffff;
}
.bookmark-list .detail input[type="submit"] {
  display: inline-block;
}
.bookmark-list .detail .button {
  display: inline-block;
}
.bookmark-list .detail > div {
  background-color: #4082b7;
  display: none;
  height: 100%;
}
.bookmark-list .detail > div.on {
  display: block;
}
#femanager_field_agreement_email_use_wrapper input[type="checkbox"] + label {
  margin-bottom: 15px;
  padding: 0;
  text-indent: 24px;
}
